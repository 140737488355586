import React, { Component } from 'react';
import logo from '../logo.svg';

import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import NavigationBar from './navbar.js';
import ServiceCard from './servicecard.js';
import Footer from './footer.js';

class Landing extends Component {

  render() {

    return (
      <div className="App">

        <Container>
          <NavigationBar />
        </Container>

        <Row className="imageBackground">
          <Container>
          <div className="imageWrapper">
            <img
            src="https://images.unsplash.com/photo-1507699622108-4be3abd695ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2851&q=80"
            alt="logo"
            />
            <div className="imageOverlay">
            Hands on, <br/>independent <br/>corporate finance<br/> advice
            </div>
          </div>

          </Container>
        </Row>

        <Container>

          <Row className="paddingStandard bgGreen">
            <Col sm={12} className="textAlignLeft">
            <p>
            Simmons Corporate Finance is a New Zealand owned independent corporate finance practice with significant experience advising  public and private companies on corporate finance and investment banking matters.
            <br/><br/>
            We provide clients with independent advice and focus on investing in our client relationships for the long term.
            </p>
            </Col>
            <Col sm={4} className="adjustedCardMargin">
              <Link to="/mergers">
                <ServiceCard
                  cardStyle="servicesCard color1 flexColumnCentred"
                  title="Mergers and Acquisitions"
                  image={1}
                  info="Acquisitions | Divestments | Private equity transactions..."
                />
              </Link>
            </Col>
            <Col sm={4} className="adjustedCardMargin">
              <Link to="/independentreports">
                <ServiceCard
                  cardStyle="servicesCard color2 flexColumnCentred"
                  title="Independent Expert's Reports"
                  image={2}
                  info="Independent adviser’s reports | Appraisal reports | Fairness opinions..."
                />
              </Link>
              </Col>
              <Col sm={4} className="adjustedCardMargin">
              <Link to="/valuations">
                <ServiceCard
                  cardStyle="servicesCard color3 flexColumnCentred"
                  title="Valuations"
                  image={3}
                  info="Shares & businesses | Intangible Assets | Derivatives..."
                />
              </Link>
              </Col>
          </Row>

          <div className="sectionBreak"/>

        </Container>



        <Footer />

      </div>
    );

  }
}

export default Landing;
