import React, { Component } from 'react';
import logo from '../logo.svg';

import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import NavigationBar from './navbar.js';
import ServiceCard from './servicecard.js';
import Footer from './footer.js';

class Mergers extends Component {

  render() {

    return (
      <div className="App">



        <Container>
          <NavigationBar />
        </Container>

        <Row className="imageBackground sectionBreakNav">
        </Row>

        <div  className="backgroundGrey">
        <Container>

          <Row className="paddingStandard bgGreen">
            <Col sm={8}>
              <div className="textAlignLeft  mainTextWrapper">
                <h1>Mergers and Acquisitions</h1>
                <p>We have extensive experience in providing hands-on practical advice to both public and private companies in respect of acquisitions and divestments. We take time to understand your M&A objectives, develop your M&A strategy alongside you and manage the transaction through to completion.</p>
              </div>
              <div className="textAlignLeft mainTextWrapper">
                <h2>Acquisitions</h2>
                <ul>
                  <li>industry analysis</li>
                  <li>identification of potential acquisition opportunities</li>
                  <li>advising on deal structure, negotiation tactics and deal risks</li>
                  <li>valuation of the target</li>
                  <li>project management of the acquisition (including management of other professional advisers)</li>
                </ul>
              </div>
              <div className="textAlignLeft mainTextWrapper">
                <h2>Divestments</h2>
                <ul>
                  <li>developing an appropriate sales strategy</li>
                  <li>establishing valuation parameters</li>
                  <li>identifying and assessing prospective purchasers</li>
                  <li>advising on marketing strategy and preparation of marketing information</li>
                  <li>project management of the sale (including management of other professional advisers)</li>
                  <li>structuring the transaction and advising on negotiations with the purchaser</li>
                  <li>ensuring a smooth completion</li>
                </ul>
              </div>
              <div className="textAlignLeft mainTextWrapper">
                <h2>Private Equity Transactions</h2>
                <ul>
                  <li>advising management teams on management buyouts (MBOs) and management buyins (MBIs)</li>
                  <li>advising closely-held companies on raising development / expansion capital</li>
                </ul>
              </div>
            </Col>
            <Col sm={4} className="adjustedCardMargin">
              <Link to="/independentreports">
                <ServiceCard
                  cardStyle="servicesCard color1 flexColumnCentred"
                  title="Independent Expert's Reports"
                  image={1}
                  info="Independent adviser’s reports | Appraisal reports | Fairness opinions..."
                />
              </Link>
              <div className="sectionBreakSmall"/>
              <Link to="/valuations">
                <ServiceCard
                  cardStyle="servicesCard color3 flexColumnCentred"
                  title="Valuations"
                  image={3}
                  info="Shares & businesses | Intangible Assets | Derivatives..."
                />
              </Link>
            </Col>
          </Row>

          <div className="sectionBreak"/>

        </Container>
        </div>

        <Footer />

      </div>
    );

  }
}

export default Mergers;
