import React, { Component } from 'react';
import logo from '../logo.svg';

import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import NavigationBar from './navbar.js';
import ServiceCard from './servicecard.js';
import Footer from './footer.js';
import Header from './header.js';

class ContactUs extends Component {

  render() {

    return (
      <div className="App">



        <Container>
          <NavigationBar />
        </Container>

        <div  className="backgroundGrey">
        <Container>

          <Row className="paddingStandard bgGreen alignLeft">
            <Col sm={8}>
              <div className="textAlignLeft  mainTextWrapper">
                <h1>Contact Us</h1>
              </div>
              <div className="textAlignLeft  mainTextWrapper">
                <p>If you are interested in how Simmons Corporate Finance can contribute to your business, please contact <Link to="/ourpeople" className="nameLink"><b>Peter Simmons</b></Link> on the methods below.</p>
              </div>
              <div className="textAlignLeft  mainTextWrapper">
                <p><b>Simmons Corporate Finance Limited</b><br/>
                    Level 5, Shortland Chambers Building,<br/>
                    70 Shortland Street,<br/>
                    Auckland</p>
              </div>
              <div className="textAlignLeft  mainTextWrapper">
                <p>PO Box 3996, Auckland 1140, New Zealand</p>
                <p><b>Tel</b> +64 9 309 1688</p>
                <p><b>Mobile</b> +64 21 669 524</p>
                <p><b>Email</b> <a href="mailto:psimmons@simmonscf.co.nz">psimmons@simmonscf.co.nz</a></p>
              </div>

              <div className="alignLeft">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.81407643685!2d174.76694341547446!3d-36.846928587110824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47fc66a54efd%3A0x5e025b4989c494f5!2sSimmons%20Corporate%20Finance%20Limited!5e0!3m2!1sen!2snz!4v1576390769067!5m2!1sen!2snz" width="300" height="300" frameborder="0" allowfullscreen=""></iframe>
              </div>

            </Col>
            <Col sm={4} className="paddingCards">
              <Link to="/mergers">
                <ServiceCard
                  cardStyle="servicesCard color1 flexColumnCentred"
                  title="Mergers and Acquisitions"
                  image={1}
                  info="Acquisitions | Divestments | Private equity transactions..."
                />
              </Link>
              <div className="sectionBreakSmall"/>
              <Link to="/independentreports">
                <ServiceCard
                  cardStyle="servicesCard color2 flexColumnCentred"
                  title="Independent Expert's Reports"
                  image={2}
                  info="Independent adviser’s reports | Appraisal reports | Fairness opinions..."
                />
              </Link>
              <div className="sectionBreakSmall"/>
              <Link to="/valuations">
                <ServiceCard
                  cardStyle="servicesCard color3 flexColumnCentred"
                  title="Valuations"
                  image={3}
                  info="Shares & businesses | Intangible Assets | Derivatives..."
                />
              </Link>
            </Col>
          </Row>

          <div className="sectionBreak"/>

        </Container>
        </div>



        <Footer />

      </div>
    );

  }
}

export default ContactUs;
