import React, { Component } from 'react';
import logo from '../logo.svg';

import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import NavigationBar from './navbar.js';
import ServiceCard from './servicecard.js';
import Footer from './footer.js';

class IndependentReports extends Component {

  render() {

    return (
      <div className="App">



        <Container>
          <NavigationBar />
        </Container>

        <Row className="imageBackground sectionBreakNav">
        </Row>

        <div  className="backgroundGrey">
        <Container>

          <Row className="paddingStandard bgGreen">
            <Col sm={8}>
              <div className="textAlignLeft  mainTextWrapper">
                <h1>Independent Expert's Reports</h1>
                <p>We have a strong track record in issuing independent expert's opinions on the fairness of transactions such as:</p>
                <ul>
                  <li>takeovers</li>
                  <li>divestments</li>
                  <li>compulsory acquisitions</li>
                  <li>the allotment of shares and derivatives</li>
                  <li>changes in control</li>
                  <li>related party transactions</li>
                </ul>
              </div>
              <div className="textAlignLeft mainTextWrapper">
                <h2>Our experience covers:</h2>
                <ul>
                  <li>Independent Adviser's Reports required under the New Zealand Takeovers Code</li>
                  <li>Appraisal Reports required under the NZX Listing Rules</li>
                  <li>Independent Reports required under High Court approved Schemes of Arrangement</li>
                  <li>Fairness Opinions requested by private companies</li>
                </ul>
              </div>
            </Col>
            <Col sm={4}>
              <Link to="/mergers">
                <ServiceCard
                  cardStyle="servicesCard color1 flexColumnCentred"
                  title="Mergers and Acquisitions"
                  image={1}
                  info="Acquisitions | Divestments | Private equity transactions..."
                />
              </Link>
              <div className="sectionBreakSmall"/>
              <Link to="/valuations">
                <ServiceCard
                  cardStyle="servicesCard color3 flexColumnCentred"
                  title="Valuations"
                  image={3}
                  info="Shares & businesses | Intangible Assets | Derivatives..."
                />
              </Link>
            </Col>
          </Row>

          <div className="sectionBreak"/>

        </Container>
        </div>



        <Footer />

      </div>
    );

  }
}

export default IndependentReports;
