import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Landing from './landing';
import Mergers from './mergers';
import IndependentReports from './independentreports';
import Valuations from './valuations';
import WhyUs from './whyus';
import OurPeople from './ourpeople';
import ContactUs from './contactus';

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


const Main = () => (
  <BrowserRouter>
    <Switch>
    <div className="backgroundGrey">
      <Route exact path="/" component={Landing} />
      <Route exact path="/mergers" component={Mergers} />
      <Route exact path="/independentreports" component={IndependentReports} />
      <Route exact path="/valuations" component={Valuations} />
      <Route exact path="/whyus" component={WhyUs} />
      <Route exact path="/ourpeople" component={OurPeople} />
      <Route exact path="/contactus" component={ContactUs} />
    </div>
    </Switch>
  </BrowserRouter>
)

export default Main;
