import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col
} from 'reactstrap';


class Footer extends Component {

  render() {

    return (
      <Row className="paddingStandard colorFooter footerMain">
        <Container>
          <Col className="textAlignLeft">
          <div className="footerWrapper">
            <div className="footerPhone">
              <div className="phoneTitle">Phone</div>
              <div className="phoneNumber">09 309 1688</div>
            </div>
            <div className="footerText">
            <br/>
            <div className="phoneTitle">Address</div>
              Level 5, Shortland Chambers Building<br/>
              70 Shortland Street<br/>
              PO Box 3996<br/>
              Auckland<br/>
              New Zealand
            <br/>
            <br/>
              © Simmons Corporate Finance Limited - New Zealand
            </div>
            </div>
          </Col>
        </Container>
      </Row>
    );

  }
}

export default Footer;
