import React, { Component } from 'react';
import './App.css';
import Main from './components/main.js';


class App extends Component {
  render() {
    return (

      <Main />

    );
  }
}

export default App;
