import React, { Component } from 'react';
import logo from '../logo.svg';

import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import NavigationBar from './navbar.js';
import ServiceCard from './servicecard.js';
import Footer from './footer.js';
import Header from './header.js';

class WhyUs extends Component {

  render() {

    return (
      <div className="App">



        <Container>
          <NavigationBar />
        </Container>

        <div  className="backgroundGrey">
        <Container>

          <Row className="paddingStandard bgGreen">
            <Col sm={8}>
              <div className="textAlignLeft  mainTextWrapper">
                <h1>Why Select Simmons Corporate Finance?</h1>

              </div>
              <div className="textAlignLeft  mainTextWrapper">
                <h2>Track Record</h2>
                <p>We have extensive experience in providing corporate finance advice to clients ranging from New Zealand's largest corporates to high growth middle market companies.</p>
              </div>
              <div className="textAlignLeft  mainTextWrapper">
                <h2>Hands on Advice</h2>
                <p>All engagements are led by experienced personnel. You will have access to Peter Simmons at all times.</p>
              </div>
              <div className="textAlignLeft  mainTextWrapper">
                <h2>Integrity</h2>
                <p>We seek to build long term relationships with our clients. We are committed to providing quality objective advice rather than being purely fee driven.</p>
              </div>
              <div className="textAlignLeft  mainTextWrapper">
                <h2>No Conflicts</h2>
                <p>We are an independent corporate finance practice, free of conflicts. We are not an integrated financial institution offering multiple products nor do we offer other (non corporate finance) services to clients.</p>
              </div>

            </Col>
            <Col sm={4}>
              <Link to="/mergers">
                <ServiceCard
                  cardStyle="servicesCard color1 flexColumnCentred"
                  title="Mergers and Acquisitions"
                  image={1}
                  info="Acquisitions | Divestments | Private equity transactions..."
                />
              </Link>
              <div className="sectionBreakSmall"/>
              <Link to="/independentreports">
                <ServiceCard
                  cardStyle="servicesCard color2 flexColumnCentred"
                  title="Independent Expert's Reports"
                  image={2}
                  info="Independent adviser’s reports | Appraisal reports | Fairness opinions..."
                />
              </Link>
              <div className="sectionBreakSmall"/>
              <Link to="/valuations">
                <ServiceCard
                  cardStyle="servicesCard color3 flexColumnCentred"
                  title="Valuations"
                  image={3}
                  info="Shares & businesses | Intangible Assets | Derivatives..."
                />
              </Link>
            </Col>
          </Row>

          <div className="sectionBreak"/>

        </Container>
        </div>



        <Footer />

      </div>
    );

  }
}

export default WhyUs;
