import React, { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';

const NavigationBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar color="white" light expand="md">
        <NavbarBrand href="/">
          <img
          src={require('../assets/scflogo.gif')}
          alt="logo"
          className="logo"
          />
        </NavbarBrand>

        <NavbarToggler onClick={toggle} />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <LinkContainer to="/">
                <NavLink>Home</NavLink>
              </LinkContainer>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Services
              </DropdownToggle>
              <DropdownMenu right>

                <LinkContainer to="/mergers">
                  <DropdownItem>
                    Mergers & Aquisitions
                  </DropdownItem>
                </LinkContainer>

                <LinkContainer to="/independentreports">
                  <DropdownItem>
                    Independent Expert's Reports
                  </DropdownItem>
                </LinkContainer>

                <LinkContainer to="/valuations">
                  <DropdownItem>
                    Valuations
                  </DropdownItem>
                </LinkContainer>

              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <LinkContainer to="/whyus">
                <NavLink>Why Us</NavLink>
              </LinkContainer>
            </NavItem>
            <NavItem>
              <LinkContainer to="/ourpeople">
                <NavLink>Our People</NavLink>
              </LinkContainer>
            </NavItem>
            <NavItem>
              <LinkContainer to="/contactus">
                <NavLink>Contact Us</NavLink>
              </LinkContainer>
            </NavItem>

          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default NavigationBar;
