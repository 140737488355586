import React, { Component } from 'react';
import logo from '../logo.svg';

import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import NavigationBar from './navbar.js';
import ServiceCard from './servicecard.js';
import Footer from './footer.js';
import Header from './header.js';

class Valuations extends Component {

  render() {

    return (
      <div className="App">



        <Container>
          <NavigationBar />
        </Container>

        <div  className="backgroundGrey">
        <Container>

          <Row className="paddingStandard bgGreen">
            <Col sm={8}>
              <div className="textAlignLeft  mainTextWrapper">
                <h1>Valuations</h1>
                <p>Simmons Corporate Finance has provided an extensive array of valuation advice to public companies, multinational corporations, public sector entities and private companies.</p>
              </div>
              <div className="textAlignLeft mainTextWrapper">
                <h2>We can advise on the value of:</h2>
                <ul>
                  <li><b>Shares and businesses</b><br/>eg: full takeovers, controlling interests, minority parcels</li>
                  <li><b>Intangible assets</b><br/>eg: brands, licences, databases, software</li>
                  <li><b>Derivatives</b><br/>eg: options, warrants, rights</li>
                </ul>
              </div>
              <div className="textAlignLeft mainTextWrapper">
                <h2>Valuations are often required for the purposes of:</h2>
                <ul>
                  <li>acquisitions</li>
                  <li>divestments</li>
                  <li>capital raising</li>
                  <li>financial reporting</li>
                  <li>tax planning</li>
                  <li>dispute resolution</li>
                </ul>
              </div>
            </Col>
            <Col sm={4}>
              <Link to="/mergers">
                <ServiceCard
                  cardStyle="servicesCard color1 flexColumnCentred"
                  title="Mergers and Acquisitions"
                  image={1}
                  info="Acquisitions | Divestments | Private equity transactions..."
                />
              </Link>
              <div className="sectionBreakSmall"/>
              <Link to="/independentreports">
                <ServiceCard
                  cardStyle="servicesCard color3 flexColumnCentred"
                  title="Independent Expert's Reports"
                  image={3}
                  info="Independent adviser’s reports | Appraisal reports | Fairness opinions..."
                />
              </Link>
            </Col>
          </Row>

          <div className="sectionBreak"/>

        </Container>
        </div>



        <Footer />

      </div>
    );

  }
}

export default Valuations;
