import React, { Component } from 'react';
import logo from '../logo.svg';

import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import NavigationBar from './navbar.js';
import ServiceCard from './servicecard.js';
import Footer from './footer.js';
import Header from './header.js';

class WhyUs extends Component {

  render() {

    return (
      <div className="App">



        <Container>
          <NavigationBar />
        </Container>

        <div  className="backgroundGrey">
        <Container>

          <Row className="paddingStandard bgGreen">
            <Col sm={8}>
              <div className="textAlignLeft  mainTextWrapper">
                <h1>Our People</h1>
              </div>
              <div className="textAlignLeft  mainTextWrapper">
                <h2>Peter Simmons</h2>
                <h3>BCom, DipBus (Finance), INFINZ (Cert)</h3>
                <p>
                Peter is a director of Simmons Corporate Finance and has over 30 years experience in corporate finance. He specialises in providing corporate finance advice on acquisitions and divestments and the valuation of shares, businesses, intangible assets and derivatives.
                <br/><br/>
                Prior to forming Simmons Corporate Finance in 2005, Peter was a partner in Deloitte and led its New Zealand corporate finance practice.
                <br/><br/>
                He has advised on numerous New Zealand and cross-border M&A transactions on both the buy-side and sell-side. These transactions have covered a range of industries such as the agriculture, logistics, media, manufacturing, packaging, retail, professional services and telecommunications industries.
                <br/><br/>
                Peter has prepared over 100 Independent Adviser's Report and Appraisal Reports on transactions undertaken by companies listed on the NZX such as; <b>Air New Zealand, Diligent Board Member Services, Fletcher Building, Kiwi Income Property Trust, Michael Hill International, NZX, SKYCITY Entertainment Group, Sky Network Television, Spark and The Warehouse.</b>
                <br/><br/>
                He has undertaken a significant number of valuations of shares and businesses ranging from public listed companies to multinational corporates and closely held companies in a wide array of industries ranging from agriculture to aviation, construction, finance, fishing, forestry, investment, logistics, manufacturing, media, printing, professional services, property, retail, technology, telecommunications, transportation, tourism and utilities industries.
                <br/><br/>
                He has undertaken valuations of intangible assets and intellectual property such as brand names, know how, patents, licences, databases and software and a variety of derivatives such as options, warrants and rights.
                </p>
              </div>

            </Col>
            <Col sm={4}>
              <Link to="/mergers">
                <ServiceCard
                  cardStyle="servicesCard color1 flexColumnCentred"
                  title="Mergers and Acquisitions"
                  image={1}
                  info="Acquisitions | Divestments | Private equity transactions..."
                />
              </Link>
              <div className="sectionBreakSmall"/>
              <Link to="/independentreports">
                <ServiceCard
                  cardStyle="servicesCard color2 flexColumnCentred"
                  title="Independent Expert's Reports"
                  image={2}
                  info="Independent adviser’s reports | Appraisal reports | Fairness opinions..."
                />
              </Link>
              <div className="sectionBreakSmall"/>
              <Link to="/valuations">
                <ServiceCard
                  cardStyle="servicesCard color3 flexColumnCentred"
                  title="Valuations"
                  image={3}
                  info="Shares & businesses | Intangible Assets | Derivatives..."
                />
              </Link>
            </Col>
          </Row>

          <div className="sectionBreak"/>

        </Container>
        </div>



        <Footer />

      </div>
    );

  }
}

export default WhyUs;
