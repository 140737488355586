import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col
} from 'reactstrap';


class ServiceCard extends Component {

  render() {

    if (this.props.image === 1){
      return (
        <div className={this.props.cardStyle}>
          <div className="cardBackground">
            <img
            src={require('../assets/listitem-1.gif')}
            alt="logo"
            className="cardImgBG"
            />
            <div className="cardWidth">
              <h4>{this.props.title}</h4>
              <p>{this.props.info}</p>
              <a>Find out more</a>
            </div>
          </div>
        </div>
      );
    }else{
      if (this.props.image === 2){
        return (
          <div className={this.props.cardStyle}>
            <div className="cardBackground">
              <img
              src={require('../assets/listitem-2.gif')}
              alt="logo"
              className="cardImgBG"
              />
              <div className="cardWidth">
                <h4>{this.props.title}</h4>
                <p>{this.props.info}</p>
                <a>Find out more</a>
              </div>
            </div>
          </div>
        );
      }else{
        return (
          <div className={this.props.cardStyle}>
            <div className="cardBackground">
              <img
              src={require('../assets/listitem-3.gif')}
              alt="logo"
              className="cardImgBG"
              />
              <div className="cardWidth">
                <h4>{this.props.title}</h4>
                <p>{this.props.info}</p>
                <a>Find out more</a>
              </div>
            </div>
          </div>
        );
      }
    }



  }
}

export default ServiceCard;
