import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col
} from 'reactstrap';


class Header extends Component {

  render() {

    return (
        <Container className="headerWrapper">
          <h4>{this.props.title}</h4>
        </Container>
    );

  }
}

export default Header;
